import { useEffect, useState } from "react";
import Accordion from "./accordian";
import Modal from "react-modal";
import { customStyles } from "../gamification/campaign";
import Text from "../../components/gamification/modals/nudge/text";
import Radio from "../../components/gamification/modals/nudge/radio";
import { useDispatch, useSelector } from "react-redux";
import {
  addEvent,
  deleteEvent,
  getEvents,
  updateEvent,
  updateEventTokenInput,
} from "../../redux/token/eventActions";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { getRequest } from "../../utils/apiRequests";
import { endPoints } from "../../utils/apiEndPoints";
import copy from "../../assets/svg/copy.svg";
import Loader from "../../components/common/loader";
import { toggleConfirmationPopup } from "../../redux/api/apiActions";
import store from "../../redux/store";
import searchIcon from "../../assets/svg/search.svg";

type selectedEventTokenCapType = {
  [token_id: string]: {
    cap_value: boolean;
  };
};

const Event = () => {
  const [activeAccordion, setActiveAccordion] = useState<number>(0);
  const [newToken, setNewToken] = useState<boolean>(false);
  const [unlimited, setUnlimited] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [transactions, setTransactions] = useState<Array<any>>([]);
  const [type, setType] = useState<string>("Non-transaction");
  const [activeEvent, setActiveEvent] = useState<string>("");
  const [tokens, setTokens] = useState<Array<any>>([]);
  const { user } = useUserAuth();
  const [allow, setAllow] = useState<boolean>(false);
  const [selectedEventTokenCap, setSelectedEventTokenCap] =
    useState<selectedEventTokenCapType>({});
  const [debit, setDebit] = useState<string>("None");

  const [search, setSearch] = useState<string>("");

  const fetchTokens = async () => {
    try {
      const data = await getRequest(
        `${(store.getState().developerTools as any).url}/${
          endPoints.allTokens
        }`,
        { Authorization: `Bearer ${user.accessToken}` }
      );
      setTokens(data.data.value);
      // setTComplete(true);
    } catch (e: any) {
      // setTComplete(false);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    fetchTokens();
  }, []);
  const {
    isGettignEvents,
    getEventsResp,
    getEventsRespStatus,
    getEventsError,
    getEventsErrorMsg,
    isAddingEvent,
    addEventRespStatus,
    addEventError,
    isUpdatingEvent,
    updateEventRespStatus,
    updateEventError,
    isDeletingEvent,
    deleteEventRespStatus,
    deleteEventError,
  } = useSelector((state: any) => state.events);

  const { inputs } = useSelector((state: any) => state.events);

  const reset = () => {
    setName("");
    setDescription("");
    setTransactions([]);
    setType("Transaction");
    setAllow(false);
    dispatch(updateEventTokenInput({}));
    setActiveEvent("");
    setUnlimited(false);
    setNewToken(false);
    setDebit("Credit");
    setSelectedEventTokenCap({});
  };
  const update = (e: any) => {
    e.preventDefault();

    if (activeEvent) {
      dispatch(
        updateEvent({
          name: name,
          description: description,
          image: "a",
          is_transaction_based: type === "Transaction" ? true : false,
          event_id: activeEvent,
          transaction_percentage:
            type === "Transaction"
              ? Object.values(inputs).map((each: any) => {
                  return {
                    token_id: each.token_id,
                    value: each.value,
                    max_cap: each.capValue || each.max_cap,
                  };
                })
              : [],
          constant_tokens:
            type === "Non-transaction"
              ? Object.values(inputs).map((each: any) => {
                  return {
                    token_id: each.token_id,
                    value: each.value,
                  };
                })
              : [],
          is_partial_allowed: allow,
          transaction_type: debit === "Debit" ? 2 : debit === "None" ? 0 : 1,
          debit_tokens: debit === "Debit" ? transactions : [],
          ratio: 1,
          token: user.accessToken,
          is_one_time_event: unlimited,
          // ratio: ratio,
          // client_id: "64d0bc127eff0438835d15d7",
        })
      );
    } else {
      dispatch(
        addEvent({
          name: name,
          description: description,
          image: "a",
          is_transaction_based: type === "Transaction" ? true : false,
          transaction_percentage:
            type === "Transaction"
              ? Object.values(inputs).map((each: any) => {
                  return {
                    token_id: each.token_id,
                    value: each.value,
                    max_cap: each.capValue || each.max_cap,
                  };
                })
              : [],
          constant_tokens:
            type === "Non-transaction"
              ? Object.values(inputs).map((each: any) => {
                  return {
                    token_id: each.token_id,
                    value: each.value,
                  };
                })
              : [],
          is_partial_allowed: allow,
          transaction_type: debit === "Debit" ? 2 : debit === "None" ? 0 : 1,
          debit_tokens: debit === "Debit" ? transactions : [],
          ratio: 1,
          token: user.accessToken,
          is_one_time_event: unlimited,
          // client_id: "64d0bc127eff0438835d15d7",
        })
      );
    }
  };

  const handleAccordionClick = (index: any) => {
    if (activeAccordion === index) {
      setActiveAccordion(-1);
    } else {
      setActiveAccordion(index);
    }
  };

  const handleSelectedTokenCapState = (
    token_id: string,
    valueType?: boolean
  ) => {
    setSelectedEventTokenCap((prevState: selectedEventTokenCapType) => ({
      ...prevState,
      [token_id]: {
        cap_value:
          valueType !== undefined ? valueType : !prevState[token_id]?.cap_value,
      },
    }));
  };

  const title = (i: any) => {
    return (
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-3">
          <div className="">
            <div className="flex items-center gap-3">
              <div className="font-bold">{i.name}</div>
              <div>|</div>
              <div className="flex items-center gap-1 text-sm bg-white px-3 rounded-[100px]">
                <div>ID: {i._id}</div>
                <img
                  src={copy}
                  alt="copy"
                  className="w-[30px] h-[30px] cursor-pointer pt-1"
                  onClick={() => navigator.clipboard.writeText(i._id)}
                />
              </div>

              {/* <div className="flex items-center gap-1">
             <div className="text-[12px]">Created by</div>
             <img
               src={p}
               alt="p"
               className="w-[30px] h-[30px] rounded-full object-cover"
             />
             <div className="text-[12px]">User name</div>
           </div> */}
            </div>
            <div className="flex items-center gap-3 mt-1">
              {/* <div>
             Created on -<b> DD/MM/YYYY</b>
           </div> */}
              <div>Monetized</div>|
              <div>
                {i.is_transaction_based
                  ? "Transaction based"
                  : "Non-transaction based"}
              </div>
            </div>
            <div className="mt-1">Description: {i.description}</div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div
            className="text-white cursor-pointer font-bold bg-[#FF7B5F] rounded-full px-5 py-2"
            onClick={() => {
              setSelectedEventTokenCap({});
              setName(i.name);
              setDescription(i.description);
              setAllow(i.is_partial_allowed);
              setUnlimited(i.is_one_time_event);
              setActiveEvent(i._id);
              setType(
                i.is_transaction_based ? "Transaction" : "Non-transaction"
              );
              setTransactions(
                i.transaction_type === 2
                  ? i.debit_tokens
                  : [
                      ...i.transaction_percentage?.map(
                        (each: any) => each.token_id
                      ),
                      ...i.constant_tokens?.map((each: any) => each.token_id),
                    ] || []
              );
              setDebit(
                i.transaction_type === 2
                  ? "Debit"
                  : i.transaction_type === 0
                  ? "None"
                  : "Credit"
              );

              i?.transaction_percentage?.forEach((token: any) => {
                handleSelectedTokenCapState(
                  token?.token_id,
                  token?.max_cap ? true : false
                );
              });

              if (i.is_transaction_based) {
                dispatch(
                  updateEventTokenInput({
                    e: i.transaction_percentage?.map((each: any) => {
                      return {
                        ...each,
                        transaction: "Transaction",
                      };
                    }),
                  })
                );
              } else {
                dispatch(
                  updateEventTokenInput({
                    e: i.constant_tokens?.map((each: any) => {
                      return {
                        ...each,
                        transaction: "Non-transaction",
                      };
                    }),
                  })
                );
              }
              setNewToken(true);
            }}
          >
            Modify
          </div>
          <div
            className="text-white cursor-pointer font-bold bg-[#FF7B5F] rounded-full px-5 py-2"
            onClick={() => {
              dispatch(
                toggleConfirmationPopup({
                  isConfirmation: true,
                  confirmationFunction: () =>
                    dispatch(
                      deleteEvent({
                        token: user.accessToken,
                        event_id: i._id,
                      })
                    ),
                  confirmationText: "Do you really want to delete the event?",
                })
              );
            }}
          >
            Delete
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (
      addEventRespStatus === 200 ||
      updateEventRespStatus === 200 ||
      deleteEventRespStatus === 200
    ) {
      reset();
      dispatch(getEvents({ token: user.accessToken }));
      dispatch(toggleConfirmationPopup({}));
    } else if (addEventError || updateEventError || deleteEventError) {
      alert("Something went wrong");
    }
  }, [isAddingEvent, isUpdatingEvent, isDeletingEvent]);
  useEffect(() => {
    if (activeEvent) {
      if (type === "Transaction") {
        if (debit === "Credit") {
          if (
            getEventsResp.data.value?.find(
              (each: any) => each._id === activeEvent
            )
          ) {
            if (
              getEventsResp.data.value.find(
                (each: any) => each._id === activeEvent
              ).is_transaction_based
            ) {
              dispatch(
                updateEventTokenInput({
                  e: getEventsResp.data.value
                    ?.find((each: any) => each._id === activeEvent)
                    ?.transaction_percentage?.map((each: any) => {
                      return {
                        ...each,
                        transaction: "Transaction",
                      };
                    }),
                })
              );
              setTransactions(
                getEventsResp.data.value
                  .find((each: any) => each._id === activeEvent)
                  ?.transaction_percentage?.map((each: any) => each.token_id)
              );
            } else {
              dispatch(updateEventTokenInput({}));
              setTransactions([]);
            }
          } else {
            dispatch(updateEventTokenInput({}));
            setTransactions([]);
          }
        } else {
          setTransactions(
            getEventsResp.data.value.find(
              (each: any) => each._id === activeEvent
            ).debit_tokens || []
          );
        }
      } else if (type === "Non-transaction") {
        if (debit === "Credit") {
          if (
            getEventsResp.data.value?.find(
              (each: any) => each._id === activeEvent
            )
          ) {
            if (
              getEventsResp.data.value.find(
                (each: any) => each._id === activeEvent
              ).is_transaction_based
            ) {
              dispatch(updateEventTokenInput({}));
              setTransactions([]);
            } else {
              dispatch(
                updateEventTokenInput({
                  e: getEventsResp.data.value
                    ?.find((each: any) => each._id === activeEvent)
                    ?.constant_tokens?.map((each: any) => {
                      return {
                        ...each,
                        transaction: "Non-transaction",
                      };
                    }),
                })
              );
              setTransactions(
                getEventsResp.data.value
                  ?.find((each: any) => each._id === activeEvent)
                  ?.constant_tokens?.map((each: any) => each.token_id)
              );
            }
          } else {
            dispatch(updateEventTokenInput({}));
            setTransactions([]);
          }
        } else {
          setTransactions(
            getEventsResp.data.value.find(
              (each: any) => each._id === activeEvent
            ).debit_tokens || []
          );
        }
      }
    }
  }, [type]);

  return (
    <div className="bg-[#F9F9F9] px-10 h-[calc(100vh-133px)] overflow-y-auto pt-5">
      {/* <div className="mt-5 flex items-center justify-between">
     <div className="text-xl font-bold">Overall analytics</div>
     <div className="relative">
       <div
         className="flex items-center cursor-pointer gap-3"
         onClick={() => setFilter(!filter)}
       >
         {date}
         <svg
           width="10"
           height="6"
           viewBox="0 0 10 6"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path
             fill-rule="evenodd"
             clip-rule="evenodd"
             d="M9.76876 0.23964C10.0771 0.55916 10.0771 1.0772 9.76876 1.39672L5.55824 5.76036C5.24993 6.07988 4.75006 6.07988 4.44176 5.76036L0.231231 1.39672C-0.0770771 1.0772 -0.0770771 0.55916 0.231231 0.23964C0.53954 -0.0798796 1.03941 -0.0798797 1.34772 0.23964L5 4.02473L8.65228 0.23964C8.96059 -0.07988 9.46046 -0.07988 9.76876 0.23964Z"
             fill="#4C4C66"
             fill-opacity="0.84"
           />
         </svg>
       </div>
       {filter && (
         <div className="flex flex-col gap-1 absolute top-7 right-0 rounded shadow bg-[#FFF2F1] w-[150px] p-3">
           <div
             onClick={() => {
               setFilter(false);
               setDate("Last 12 months");
             }}
           >
             Last 12 months
           </div>
           <div
             onClick={() => {
               setFilter(false);
               setDate("Last 6 months");
             }}
           >
             Last 6 months
           </div>
           <div
             onClick={() => {
               setFilter(false);
               setDate("Last 3 months");
             }}
           >
             Last 3 months
           </div>
           <div>Custom date</div>
         </div>
       )}
     </div>
   </div>
   <div className="grid grid-cols-12 gap-5 mt-3">
     <div className="col-span-6 h-[300px]">
       <ColumnChart
         data={{
           title: "Most performed activity distribution",
           data: [
             { category: "Category A", value: 50 },
             { category: "Category B", value: 75 },
             { category: "Category C", value: 30 },
             { category: "Category D", value: 47 },
             { category: "Category E", value: 12 },
             { category: "Category F", value: 39 },
           ],
         }}
       />
     </div>
     <div className="col-span-6">
       <ColumnChart
         data={{
           title: "Performance distribution",
           data: [
             { category: "Category A", value: 50 },
             { category: "Category B", value: 75 },
             { category: "Category C", value: 24 },
             { category: "Category D", value: 51 },
             { category: "Category E", value: 11 },
             { category: "Category F", value: 47 },
           ],
         }}
       />
     </div>
   </div> */}
      <div className="flex items-center justify-between mb-5">
        <div className="text-xl font-bold">Events</div>
        <div className="flex items-center gap-3">
          <Text
            text={search}
            setText={setSearch}
            place={"Search events"}
            full={false}
            type={"text"}
            required={false}
            icon={searchIcon}
          />
          <button
            className="px-3 py-2 text-white font-bold bg-[#FF7B5F] cursor-pointer rounded"
            onClick={() => {
              // setAdd(false);
              setNewToken(true);
            }}
          >
            Create Event
          </button>
        </div>
      </div>
      {getEventsRespStatus === 200 && getEventsResp.data.value.length === 0 && (
        <div className="py-5 text-center">Add an event!</div>
      )}
      {isGettignEvents && (
        <div className="flex items-center justify-center mt-5">
          <Loader heavy={true} />
        </div>
      )}
      {getEventsError && getEventsErrorMsg}
      {getEventsResp?.data?.value?.length < 1 ? (
        <div className="flex items-center justify-center mt-5 border-4 border-[#EFF0F6] p-3 bg-white rounded">
          No event found. Add one!
        </div>
      ) : getEventsResp.data?.value?.filter((each: any) =>
          each?.name?.toLowerCase()?.includes(search?.toLowerCase())
        )?.length < 1 ? (
        <div className="flex items-center justify-center mt-5 border-4 border-[#EFF0F6] p-3 bg-white rounded">
          No result found :(
        </div>
      ) : null}
      {getEventsRespStatus === 200 &&
        getEventsResp.data.value
          ?.filter((each: any) =>
            each?.name?.toLowerCase()?.includes(search?.toLowerCase())
          )
          .map((each: any, i: number) => {
            return (
              <Accordion
                key={i}
                title={title(each)}
                onClick={() => handleAccordionClick(i)}
                isOpen={activeAccordion === i}
                eachGroupType={[]}
                empty={true}
              ></Accordion>
            );
          })}
      <Modal
        style={customStyles}
        isOpen={newToken}
        onRequestClose={() => reset()}
        shouldCloseOnOverlayClick={true}
      >
        <form onSubmit={update}>
          <div className="p-5 max-h-[600px] overflow-y-auto">
            <div className="flex justify-between w-[750px]">
              <div>
                <div className="text-2xl text-[#4C4C66] font-bold">
                  Add Event
                </div>
                <div className="text-[#3189B4] mt-3">Event details..</div>
              </div>
              <div
                className="text-xl text-[#4C4C66] cursor-pointer"
                onClick={() => reset()}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                    fill="#4C4C66"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-5 flex gap-5">
              <div className="w-full">
                <div className="text-[14px] text-[#4C4C66]">Name of event</div>
                <Text
                  text={name}
                  setText={setName}
                  place={"- eg: coin of fortune -"}
                  full={true}
                  required={true}
                />
                <div className="text-[14px] text-[#4C4C66] mt-3">
                  Event description
                </div>
                <Text
                  text={description}
                  setText={setDescription}
                  place={"- eg: for coins from future -"}
                  full={true}
                />
                <div
                  className="mt-3 rounded border-2 border-[#FFAFA8] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px]"
                  onClick={() => setUnlimited(!unlimited)}
                >
                  <div className="flex items-center gap-3">
                    {unlimited ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="#5F5E76"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                          fill="#FFAFA8"
                        />
                      </svg>
                    )}
                    <div className="text-lg text-[#4C4C66]">
                      Is one-time event?
                    </div>
                  </div>
                </div>
                <div className="text-[14px] text-[#4C4C66] mt-3">
                  Transaction Type
                </div>
                <div className="flex items-center gap-3">
                  {["None", "Credit", "Debit"].map((each: string) => {
                    return (
                      <Radio
                        selected={debit}
                        setSelected={setDebit}
                        each={each}
                        multi={false}
                      />
                    );
                  })}
                </div>
                {debit !== "None" && (
                  <>
                    {debit !== "Debit" && (
                      <>
                        <div className="text-[14px] text-[#4C4C66] mt-3">
                          Event Type
                        </div>
                        <div className="flex items-center gap-3">
                          {["Transaction", "Non-transaction"].map(
                            (each: string) => {
                              return (
                                <Radio
                                  selected={type}
                                  setSelected={setType}
                                  each={each}
                                  multi={false}
                                />
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                    {
                      <div className="mt-3 bg-[#FFE8E6] rounded w-full max-h-[150px] overflow-y-auto p-3 flex flex-col gap-3">
                        {tokens.map((each: any) => {
                          return (
                            <div
                              className={
                                "rounded border-2 border-[#FFAFA8]  cursor-pointer p-3" +
                                (transactions.includes(each._id)
                                  ? " bg-white"
                                  : " bg-[#FFF2F2]")
                              }
                            >
                              <div
                                className={"flex items-center gap-3"}
                                onClick={() => {
                                  if (transactions.includes(each._id)) {
                                    setTransactions(
                                      transactions.filter(
                                        (element: any) => element !== each._id
                                      )
                                    );
                                  } else {
                                    setTransactions(
                                      Array.from(
                                        new Set([...transactions, each._id])
                                      )
                                    );
                                  }
                                }}
                              >
                                {transactions.includes(each._id) ? (
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                      fill="#5F5E76"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                      fill="#FFAFA8"
                                    />
                                  </svg>
                                )}
                                <div className="flex items-center gap-3">
                                  <img
                                    src={each.image}
                                    alt={each.image}
                                    className="w-[30px]"
                                  />
                                  <div className="text-lg font-bold">
                                    {each.name}
                                  </div>
                                </div>
                              </div>
                              {transactions.includes(each._id) &&
                              debit !== "Debit" ? (
                                <>
                                  <div
                                    className=" flex flex-row"
                                    onClick={(e: any) => e.stopPropagation()}
                                  >
                                    <div>
                                      <div className="mt-4 text-[14px] text-[#4C4C66]">
                                        Enter{" "}
                                        {type === "Transaction"
                                          ? "percentage"
                                          : "value"}
                                      </div>
                                      <Text
                                        text={inputs[each._id]?.["value"]}
                                        setText={(e: any) => {
                                          dispatch(
                                            updateEventTokenInput({
                                              id: each._id,
                                              value: e,
                                              capValue:
                                                inputs[each._id]?.["max_cap"],
                                              transaction: "Transaction",
                                            })
                                          );
                                        }}
                                        place={"e.g 5"}
                                        full={false}
                                        required={true}
                                        type={"number"}
                                      />
                                    </div>
                                    {type === "Transaction" ? (
                                      <div>
                                        {" "}
                                        <div className="cursor-pointer gap-3 p-3 max-w-[750px]">
                                          <div className="flex flex-row items-center gap-3">
                                            <div className="">
                                              <div
                                                className="items-center flex flex-row"
                                                onClick={() =>
                                                  handleSelectedTokenCapState(
                                                    each._id
                                                  )
                                                }
                                              >
                                                {selectedEventTokenCap[each._id]
                                                  ?.cap_value ? (
                                                  <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                      fill="#5F5E76"
                                                    />
                                                  </svg>
                                                ) : (
                                                  <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                      fill="#FFAFA8"
                                                    />
                                                  </svg>
                                                )}
                                                <div className="text-[14px] text-[#4C4C66]">
                                                  Enter Cap Value
                                                </div>
                                              </div>
                                              {selectedEventTokenCap[each._id]
                                                ?.cap_value ? (
                                                <Text
                                                  text={
                                                    inputs[each._id]?.[
                                                      "max_cap"
                                                    ]
                                                  }
                                                  setText={(e: any) => {
                                                    dispatch(
                                                      updateEventTokenInput({
                                                        id: each._id,
                                                        capValue: e,
                                                        value:
                                                          inputs[each._id]?.[
                                                            "value"
                                                          ],
                                                        transaction:
                                                          "Transaction",
                                                      })
                                                    );
                                                  }}
                                                  place="e.g 5"
                                                  full={false}
                                                  required={true}
                                                  type="number"
                                                />
                                              ) : (
                                                <>
                                                  <input
                                                    type="text"
                                                    className="text-gray-500 bg-gray-100 rounded-md p-2 mt-1 w-full"
                                                    placeholder="e.g 5"
                                                    disabled
                                                  />
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="mt-3 text-[14px] text-[#4C4C66]">
                                    Note : Enter{" "}
                                    {type === "Transaction"
                                      ? "percentage"
                                      : "value"}{" "}
                                    of the transaction amount to be given as
                                    token
                                  </div>
                                </>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    }
                  </>
                )}
                {debit === "None" ? (
                  <div className="text-[#4C4C66] mt-5">
                    Note: None type is used for events which does not include
                    any transaction of tokens
                  </div>
                ) : null}
              </div>
            </div>
            {debit === "Credit" && (
              <div className="text-[#4C4C66] mt-5">
                Note : Select the token & enter the percentage of the
                transaction amount to be given as token
              </div>
            )}
            {debit === "Debit" && (
              <div className="text-[#4C4C66] mt-5">
                Note : Amount of the transaction to be sent while triggering
                event
              </div>
            )}
            {type === "Non-transaction" && debit === "Credit" && (
              <div
                className="mt-3 rounded border-2 border-[#FFAFA8] bg-[#FFE8E6] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px]"
                onClick={() => setAllow(!allow)}
              >
                <div className="flex items-center gap-3">
                  {allow ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                        fill="#5F5E76"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                        fill="#FFAFA8"
                      />
                    </svg>
                  )}
                  <div className="text-lg text-[#4C4C66]">
                    Allow partial crediting of rewards
                  </div>
                </div>
                <div className="text-[14px] text-[#4C4C66]">
                  If you want to credit partial coins based on the performance
                  of user in this event. You can configure the partial
                  percentage amount in the SDK while calling the `trackEvent`
                  function.
                </div>
              </div>
            )}
            <div className="flex items-center justify-end px-5 my-5">
              <button
                className="px-3 py-2 text-white text-xl font-bold bg-[#FF7B5F] cursor-pointer rounded"
                type={"submit"}
                disabled={isAddingEvent || isUpdatingEvent}
              >
                {isAddingEvent || isUpdatingEvent ? <Loader /> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};
export default Event;
